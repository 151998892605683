<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId=='createTestimonial'" :class="modalState?'show':''" tabindex="-1" role="dialog" style="display: block;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Testimonial</h5>
            <a class="close"  @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="addTestimonial" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Testimonial Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="testimonialImage" style="border: 1px dashed;">
                              <img v-if="formData.image"
                                   :src="formData.image"
                                   class="uploading-image"
                                   style="width: 5rem;height: 4rem;"
                              />
                              <span v-else style="padding: 1rem;">Image</span>
                            </label>
                            <input
                                    type="file"
                                    id="testimonialImage"
                                    @change="base64Image"
                                    style="display:none;"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Name</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.name.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Full Name"
                                    @blur="$v.formData.name.$touch()"
                                    v-model.trim="formData.name"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.name.$error">
                            <p class="error_level" v-if="!$v.formData.name.required">Name field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.name.minLength"
                            >At least 2 character name.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Designation</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.designation.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Designation"
                                    @blur="$v.formData.designation.$touch()"
                                    v-model.trim.number="formData.designation"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.designation.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.designation.required"
                            >Designation field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.designation.minLength"
                            >At least 2 character designation.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Explanation</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.explanation.$error }">
                    <textarea
                            type="text"
                            class="form-control border-radius-0"
                            placeholder="Explanation"
                            @blur="$v.formData.explanation.$touch()"
                            v-model.trim.number="formData.explanation"
                            required
                            style="min-height:3rem;"
                    ></textarea>
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.explanation.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.explanation.required"
                            >Explanation field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.explanation.minLength"
                            >At least 50 character explanation.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.explanation.maxLength"
                            >Max 500 character explanation.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Status</label>
                          </div>
                          <div class="col-md-6">
                            <input
                                    type="radio"
                                    name="status"
                                    id="activateTestimonial"
                                    value="1"
                                    checked
                                    v-model="formData.status"
                            />
                            <label for="activateTestimonial">
                              <span class="ml-2">Activate</span>
                            </label>
                            <input
                                    type="radio"
                                    class="ml-4"
                                    name="status"
                                    id="deactivateTestimonial"
                                    value="0"
                                    v-model="formData.status"
                            />
                            <label for="deactivateTestimonial">
                              <span class="ml-2">Deactivate</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import {
    maxLength,
    minLength,
    numeric,
    required,
  } from "vuelidate/lib/validators";
  import { mapGetters } from "vuex";
  import axios from "axios";
  export default {
    computed: {
      ...mapGetters([
        "apiRoot",
        "token",
        "processing",
        "fileLocation",
        "eventMessage",
        "dataLists",
        "modalId",
        "modalState",
      ]),
    },
    data() {
      return {
        formData: {
          name: "",
          designation: "",
          explanation: "",
          image: null,
          status: 1,
        },
      };
    },
    validations: {
      formData: {
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        designation: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(255),
        },
        explanation: {
          required,
          minLength: minLength(50),
          maxLength: maxLength(500),
        },
        status: { required, numeric },
      },
    },
    methods: {
      base64Image(e) {
        this.formData.image = null;
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.formData.image = e.target.result;
        };
      },
      addTestimonial() {
        if (!this.$v.formData.$invalid) {
          this.$store.commit("setApiUrl", "api/testimonials");
          this.$store.commit("addData", this.formData);
        } else {
          this.$store.commit("setEventMessage", `Please fill required fields.`);
        }
      },
    },
    watch: {
      eventMessage(value) {
        if (value && value.indexOf("success") >= 0) {
          this.formData.name = "";
          this.formData.designation = "";
          this.formData.explanation = "";
        }
      },
    },
  };
</script>